import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import tw from "twin.macro"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const BlogPage = ({
	data: {
		allWpPost: { nodes: posts },
	},
	pageContext: { nextPagePath, previousPagePath, blogUri },
}) => {
	return (
		<Layout>
			<SEO title="All posts" />

			<main className="container">
				{!posts.length && <p>No blog posts found. Add posts to your WordPress site and they'll appear here!</p>}

				{posts.length && (
					<React.Fragment>
						<ol className="post-list">
							{posts.map(post => {
								const title = post.title

								return (
									<li key={post.id}>
										<article
											tw="my-4 md:my-8 xl:my-12"
											itemScope
											itemType="http://schema.org/Article"
										>
											<header>
												<h2 itemProp="headline">
													<Link to={`${blogUri}${post.uri}`} itemProp="url">
														{parse(title)}
													</Link>
												</h2>
											</header>

											{!!post.excerpt && (
												<section itemProp="description" className="prose">
													{parse(post.excerpt)}
												</section>
											)}
										</article>
									</li>
								)
							})}
						</ol>

						<nav tw="my-12 md:my-16 xl:my-20 pt-4 md:pt-8 lg:pt-12 border-t-2 border-b-0 border-catskill">
							<ul tw="flex flex-wrap justify-between list-none">
								<li>{previousPagePath && <Link to={previousPagePath}>Previous page</Link>}</li>

								<li>{nextPagePath && <Link to={nextPagePath}>Next page</Link>}</li>
							</ul>
						</nav>
					</React.Fragment>
				)}
			</main>
		</Layout>
	)
}

export default BlogPage

export const pageQuery = graphql`
	query BlogPage($offset: Int!, $postsPerPage: Int!) {
		allWpPost(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
			nodes {
				uri
				date(formatString: "MMMM DD, YYYY")
				title
				excerpt
				seo {
					metaTitle
					metaDescription
				}
			}
		}
	}
`
